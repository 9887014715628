<script>
import { mapState } from 'vuex';
import FormWrapper from '@/components/FormWrapper';
import Loader from '@/components/Loader';

export default {
  name: 'EmbeddedYotiSign',
  components: { FormWrapper, Loader },
  data() {
    return {
      embeddedYotiUrl: `${process.env.VUE_APP_EMBEDDED_YOTI_SIGN_ENDPOINT}/${this.token}`,
    };
  },
  props: ['entityType', 'entityId', 'token'],
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    navigateToCaseView() {
      this.navigate('ViewEntity', { entityType: this.entityType, entityId: this.entityId });
    },
  },
};
</script>

<template>
  <form-wrapper :entityType="entityType" :entityId="entityId" class="mb-5">
    <div class="home" slot="form-content">
      <loader v-if="loading">Loading Document to sign...</loader>
      <div v-else class="row">
        <div class="col">
          <!-- Embedded Yoti iframe -->
          <div class="col-md-12 pb-2">
            <p>
              Having trouble signing this document? Click <a :href="embeddedYotiUrl" target="_blank">here</a> to open in
              a separate tab.
            </p>
            <iframe style="border: none" width="100%" height="750px" :src="embeddedYotiUrl" allowfullscreen></iframe>
          </div>
          <!-- /end Embedded Yoti iframe -->

          <div class="col-md-12 pb-2">
            <button class="btn btn-info border-info float-right" @click="navigateToCaseView">Return to Case</button>
          </div>
        </div>
      </div>
    </div>
  </form-wrapper>
</template>
<style lang="scss" scoped></style>
